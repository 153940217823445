<template>
    <div class="search-page">
        <IndexTier :hasSearch="true">
            <template #results="{ results }">
                <div class="desktop-results">
                    <ContentCardBlock
                        v-for="result in results"
                        :key="result.id"
                        :related="result"
                        orientation="wide"
                        context="inline"
                        size="100"
                        :isProportionate="true"
                        :hideDateCard="true"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                </div>
                <div class="mobile-results">
                    <ContentCardBlock
                        v-for="result in results"
                        :key="result.id"
                        :related="result"
                        orientation="wide"
                        context="inline"
                        class="result"
                        size="50"
                        :hideDateCard="true"
                        :showPublicationDate="true"
                        :pageLanguage="pageLanguage"
                    />
                </div>
            </template>
        </IndexTier>
    </div>
</template>

<script setup>
const headerAttributesStore = useHeaderAttributesStore();
const { currentCenter } = useCenters();
const currentCenterSlug = currentCenter.value.slug;
const { locale } = useLocale();
let GTMCenter = 'CEIP';
if (currentCenterSlug.length) {
    if (currentCenterSlug === 'china') {
        GTMCenter = 'Carnegie China';
    } else if (currentCenterSlug === 'europe') {
        GTMCenter = 'Carnegie Europe';
    } else if (currentCenterSlug === 'russiaEurasia') {
        GTMCenter = 'Carnegie Russia Eurasia';
    } else if (currentCenterSlug === 'india') {
        GTMCenter = 'Carnegie India';
    } else if (currentCenterSlug === 'middleEast') {
        GTMCenter = 'Carnegie Middle East Center';
    }
}
const GTMLang = locale._value;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter,
        Doctype: 'Search Page',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
});
const pageLanguage = locale.value;
useSeoMeta({
    title: `Search - ${currentCenter.value.title}`,
});
</script>

<style lang="scss" scoped>
.search-page {
    padding-top: vertical-space(6);
}

.desktop-results {
    display: none;

    @include media-query(phone-mw) {
        display: block;
    }
}

.mobile-results {
    @include media-query(phone-mw) {
        display: none;
    }
}
</style>
